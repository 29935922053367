'use client';

import { mq } from '@/lib/StyledComponents/mq';
import { H3 } from '@/lib/StyledComponents/Typography';
import styled, { css } from 'styled-components';

export type AccordionItemProps = {
  title: string;
  content: string;
  isOpen: boolean;
  onToggle?: () => void;
};

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors.text.dark};
  border-radius: 0.625rem;

  overflow: hidden;

  cursor: pointer;
`;

const AccordionItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.25rem;

  background-color: ${({ theme }) => theme.colors.main.info};
  color: ${({ theme }) => theme.colors.text.light};

  h3 {
    font-size: clamp(1.125rem, 2vw, 1.5rem);
    margin-bottom: 0;
  }

  ${mq('laptop')} {
    padding: 2rem;
  }
`;

type AccordionItemContentProps = {
  $isOpen?: boolean;
};

const AccordionItemContent = styled.div<AccordionItemContentProps>`
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

  max-height: 0;

  ${({ $isOpen: isOpen }) =>
    isOpen &&
    css`
      max-height: 40rem;
      padding: 1.25rem;
    `}

  background-color: ${({ theme }) => theme.colors.background.light};

  p {
    opacity: 0;
  }
`;

type AccordionIconProps = {
  $isOpen?: boolean;
};

const AccordionIcon = styled.span<AccordionIconProps>`
  position: relative;
  top: 0.25rem;
  width: 0.75rem;
  height: 0.75rem;

  span {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.background.light};
    width: 100%;
    height: 4px;
  }

  span:first-child {
    transform: rotate(${({ $isOpen: isOpen }) => (isOpen ? '0' : '90deg')});
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

export const AccordionItem = ({
  title,
  content,
  isOpen,
  onToggle,
}: AccordionItemProps) => {
  const handleToggle = () => {
    onToggle?.();
  };

  return (
    <AccordionContainer onClick={handleToggle}>
      <AccordionItemHeader>
        <H3>{title}</H3>
        <AccordionIcon $isOpen={isOpen}>
          <span />
          <span />
        </AccordionIcon>
      </AccordionItemHeader>
      <AccordionItemContent $isOpen={isOpen}>{content}</AccordionItemContent>
    </AccordionContainer>
  );
};
