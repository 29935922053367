'use client';

import { mq } from '@/lib/StyledComponents/mq';
import { BodyText, H3, H2 } from '@/lib/StyledComponents/Typography';
import { type UpsItemBlockData } from '@/schema/cms/blocks/UpsItemBlockSchema';
import Image from 'next/image';
import { type PropsWithChildren } from 'react';
import styled from 'styled-components';

const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  text-align: center;
  padding: 3.75rem 1.875rem;

  ${mq('laptop')} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    grid-gap: 3rem;
    gap: 3rem;
    max-width: 80rem;
    margin: 0 auto;
  }
`;

const ListItem = styled.li`
  ${mq('laptop')} {
    width: 30%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h3 {
    margin-bottom: 0.75rem;

    ${mq('laptop')} {
      font-size: 1.5rem;
    }
  }

  p {
    word-break: break-word;
  }
`;

const Icon = styled.div`
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

const Text = styled.div``;

const Title = styled(H2)`
  margin: 0;
  padding-top: 3.75rem;
  text-align: center;
`;

type USPListProps = PropsWithChildren<{
  uspItems: UpsItemBlockData[];
  title?: string | null;
}>;

export const USPList = ({ uspItems, title }: USPListProps) => {
  if (uspItems.length === 0) return null;

  return (
    <>
      {title && <Title>{title}</Title>}
      <ListWrapper>
        {uspItems.map(item => (
          <ListItem key={item.title}>
            <Icon>
              <Image
                src={item.icon.url}
                alt={item.icon.alt}
                width={32}
                height={32}
              />
            </Icon>

            <Text>
              <H3>{item.title}</H3>
              <BodyText>{item.description}</BodyText>
            </Text>
          </ListItem>
        ))}
      </ListWrapper>
    </>
  );
};
