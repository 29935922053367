import { createContext } from 'react';
import { type ValuationContextType } from '../../types';

export const ValuationContext = createContext<ValuationContextType>({
  regNr: '',
  setRegNr: async () => {},
  clearRegNr: async () => {},
  distance: null,
  packages: [],
  setPackages: () => {},
  standardEquipment: [],
  setStandardEquipment: () => {},
  extraEquipment: [],
  setExtraEquipment: () => {},
  storeFilters: async () => {},
});
