'use client';

import { useCallback, useState } from 'react';
import { AccordionItem } from './AccordionItem';
import styled from 'styled-components';
import { mq } from '@/lib/StyledComponents/mq';

type AccordionProps = {
  items: {
    title: string;
    content: string;
  }[];
};

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;

  border-radius: 0.625rem;

  ${mq('laptop')} {
    gap: 1rem;
  }
`;

export const Accordion = ({ items }: AccordionProps) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = useCallback((index: number) => {
    return () =>
      setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  }, []);

  return (
    <AccordionContainer>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          {...item}
          onToggle={handleToggle(index)}
          isOpen={index === openIndex}
        />
      ))}
    </AccordionContainer>
  );
};
