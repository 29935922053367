'use client';
import { useRef, useEffect } from 'react';
import { useValuationContext } from './useValuationContext';

export const useClearRegNr = (clearCookie: boolean) => {
  const clearRef = useRef(clearCookie);
  const { clearRegNr } = useValuationContext();

  const regNrRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const clear = async () => {
      if (clearRef.current) {
        clearRef.current = false;

        if (regNrRef.current) regNrRef.current.value = '';

        await clearRegNr();
      }
    };

    clear();
  }, [clearCookie, clearRegNr]);

  return regNrRef;
};
