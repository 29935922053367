'use client';

import { BodyText, H2 } from '@/lib/StyledComponents/Typography';
import { type FaqAccordionBlockData } from '@/schema/cms/blocks/FaqAccordionBlockSchema';
import { Accordion } from './shared/Accordion';
import styled from 'styled-components';
import { mq } from '@/lib/StyledComponents/mq';

const FAQContainer = styled.div`
  padding-top: clamp(7.5rem, 5vw, 6rem);
  padding-bottom: clamp(7.5rem, 5vw, 6rem);
`;

const FAQOuter = styled.div`
  max-width: 90rem;
  padding: 0 1.875rem;
  margin: 0 auto;
`;

const FAQText = styled.div`
  text-align: center;

  p {
    margin-bottom: 2.25rem;
  }

  ${mq('laptop')} {
    margin: 0 auto;
    max-width: 40rem;

    h2 {
      font-size: 2.25rem;
    }
  }
`;

type FAQProps = {
  title: string | null;
  description: string | null;
  faqItems: FaqAccordionBlockData[];
};

export const FAQSection = ({ title, description, faqItems }: FAQProps) => {
  return (
    <FAQContainer>
      <FAQOuter>
        {(title || description) && (
          <FAQText>
            {title && <H2>{title}</H2>}
            {description && <BodyText>{description}</BodyText>}
          </FAQText>
        )}

        <Accordion
          items={faqItems.map(faq => ({
            title: faq.question,
            content: faq.answer,
          }))}
        />
      </FAQOuter>
    </FAQContainer>
  );
};
