'use client';

import { mq } from '@/lib/StyledComponents/mq';
import { H3, H4 } from '@/lib/StyledComponents/Typography';
import { isHexColor } from '@/lib/StyledComponents/utils';
import { type ServiceBlockData } from '@/schema/cms/blocks/ServiceBlockSchema';
import Image from 'next/image';
import Link from 'next/link';
import styled, { css } from 'styled-components';

const ServiceItem = styled.li`
  background-color: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.text.dark};
  margin-bottom: 2rem;
  width: 95%;
  scroll-margin-top: 5.75rem;

  ${mq('laptop')} {
    width: 70%;
  }

  &:nth-of-type(odd) {
    border-top-right-radius: 16rem;
    border-bottom-right-radius: 16rem;

    ${mq(null, 'laptop')} {
      padding-right: 4rem;
    }

    a {
      flex-direction: row;
    }
  }

  &:nth-of-type(2n) {
    margin-left: auto;
    border-top-left-radius: 16rem;
    border-bottom-left-radius: 16rem;

    ${mq(null, 'laptop')} {
      padding-left: 4rem;
    }

    a {
      ${mq('laptop')} {
        flex-direction: row-reverse;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  padding: 2rem;
  text-decoration: none;
  justify-content: flex-end;

  ${mq('laptop')} {
    grid-gap: 6rem;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 0.875rem;
    word-break: break-word;
    margin: 1.5rem 0 0.875rem;
  }

  h4 {
    font-size: 1rem;
    margin: 0;
  }

  ${mq('laptop')} {
    max-width: 35rem;
    gap: 1.5rem;

    p {
      font-size: 1rem;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 0.75rem;

  h3 {
    font-size: 2rem;
    margin: 0;
  }

  ${mq(null, 'laptop')} {
    h3 {
      font-size: 1.125rem;
    }
  }
`;

type CircleSizeProps = {
  $visibleLaptop?: boolean;
  size: number;
};

type CircleOuterProps = Pick<ServiceBlockData, 'color'> & CircleSizeProps;

const CircleOuter = styled.div<CircleOuterProps>`
  display: ${({ $visibleLaptop }) => ($visibleLaptop ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  border-radius: 16rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

  ${({ color, theme }) =>
    color &&
    css`
      background-color: ${isHexColor(color)
        ? color.hex
        : theme.colors.main[color]};
    `}

  width: ${({ size }) => size}rem;

  ${mq('laptop')} {
    display: ${({ $visibleLaptop }) => ($visibleLaptop ? 'flex' : 'none')};
  }
`;

type CircleInnerProps = CircleSizeProps;

const CircleInner = styled.div<CircleInnerProps>`
  display: ${({ $visibleLaptop }) => ($visibleLaptop ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  border-radius: 16rem;

  width: ${({ size }) => size}rem;
  height: ${({ size }) => size}rem;

  ${mq('laptop')} {
    display: ${({ $visibleLaptop }) => ($visibleLaptop ? 'flex' : 'none')};
  }

  img {
    border-radius: 0;
  }
`;

type ServiceProps = Pick<
  ServiceBlockData,
  'name' | 'description' | 'slug' | 'icon' | 'color' | 'linkText'
> & {
  reverse?: boolean;
  id: string;
};

export const Service = ({
  id,
  slug,
  icon,
  color,
  name,
  description,
  linkText,
}: ServiceProps) => {
  return (
    <ServiceItem id={id}>
      <StyledLink href={`/services/${slug}`} data-testid="service-link">
        <Text>
          <TitleWrapper>
            <CircleOuter color={color} size={3.25}>
              <CircleInner size={3.25}>
                <Image src={icon.url} alt={icon.alt} width={14} height={14} />
              </CircleInner>
            </CircleOuter>
            <H3>{name}</H3>
          </TitleWrapper>
          <p>{description}</p>
          <H4>{linkText}</H4>
        </Text>
        <CircleOuter color={color} $visibleLaptop size={17}>
          <CircleInner $visibleLaptop size={17}>
            <Image src={icon.url} alt={icon.alt} width={100} height={100} />
          </CircleInner>
        </CircleOuter>
      </StyledLink>
    </ServiceItem>
  );
};
