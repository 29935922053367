'use client';

import { H2, LargeBodyText } from '@/lib/StyledComponents/Typography';
import { Service } from './CmsBlocks/Service';
import { type ServicePageModel } from '@/schema/cms/models/ServicePageModel.schema';
import { type ServiceBlockData } from '@/schema/cms/blocks/ServiceBlockSchema';
import styled from 'styled-components';
import { theme } from '@/lib/StyledComponents/theme';
import { mq } from '@/lib/StyledComponents/mq';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';

const Container = styled.div`
  padding-top: 6rem;

  ${mq(null, 'laptop')} {
    padding-top: 2rem;
  }
`;

const AnchorMenu = styled.ul`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0 1.75rem;
  margin-bottom: 6rem;
`;

const LinkItem = styled.li<{ $hoverColor: string }>`
  white-space: nowrap;

  p {
    font-weight: ${theme.fontWeights.black};
    display: flex;
    align-items: center;
    transition: color 0.2s ease;
    text-transform: uppercase;
  }

  p::before {
    content: '';
    background-color: ${({ $hoverColor }) => $hoverColor};
    height: 0.625rem;
    min-width: 0.625rem;
    border-radius: 100%;
    margin-right: 0.5rem;
  }

  p:hover {
    cursor: pointer;
    color: ${({ $hoverColor }) => $hoverColor} !important;
  }

  ${mq(null, 'laptop')} {
    p {
      font-size: 0.875rem;
    }
  }
`;

const Title = styled(H2)`
  font-size: 2.25rem;
  margin-bottom: 2.25rem;

  ${mq(null, 'laptop')} {
    font-size: 1.5rem;
  }
`;

const List = styled.ul`
  padding-bottom: clamp(7.5rem, 5vw, 6rem);
`;

type Service = Pick<ServicePageModel, 'id'> & {
  service: Pick<
    ServiceBlockData,
    'slug' | 'icon' | 'name' | 'description' | 'linkText' | 'color'
  >;
};
type ServicesSectionProps = {
  services: Service[];
  title: string | null;
};

export const ServicesSection = ({ services, title }: ServicesSectionProps) => {
  const { t } = useTranslation();
  const pathname = usePathname();
  const filteredServices = services?.filter(service =>
    Boolean(service.service)
  );

  if (filteredServices?.length === 0 && !title) {
    return null;
  }

  function handleScrollClick(id: string) {
    const anchor = document.getElementById(id);
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <Container>
      {pathname === '/' + t('ROUTES.SERVICES') && (
        <AnchorMenu>
          {services.map(({ id, service }) => {
            return (
              <LinkItem key={id} $hoverColor={service.color.hex ?? '#FFFFFF'}>
                <LargeBodyText onClick={() => handleScrollClick(id)}>
                  {service.name}
                </LargeBodyText>
              </LinkItem>
            );
          })}
        </AnchorMenu>
      )}

      {title && <Title $align="center">{title}</Title>}

      {filteredServices.length > 0 && (
        <List>
          {filteredServices.map(service => (
            <Service
              id={service.id}
              key={service.id}
              slug={service.service.slug}
              icon={service.service.icon}
              name={service.service.name}
              description={service.service.description}
              linkText={service.service.linkText}
              color={service.service.color}
            />
          ))}
        </List>
      )}
    </Container>
  );
};
