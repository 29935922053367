'use client';

import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/navigation';

import { Button } from '../../../(components)/shared/Button';
import { Input } from '../../../(components)/shared/Input';
import { useValuationContext } from '../_hooks/useValuationContext';
import { mq } from '@/lib/StyledComponents/mq';
import { useClearRegNr } from '../_hooks/useClearRegNr';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.12rem;

  width: 100%;
  max-width: 30rem;

  ${mq('laptop')} {
    max-width: 36rem;
  }
`;

const Form = styled.form<FormProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.12rem;

  ${mq('laptop')} {
    flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
    gap: 1.5rem;
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.main.error};
  font-weight: ${({ theme }) => theme.fontWeights.black};
`;

type FormProps = {
  flexDirection?: 'column' | 'row';
};

type ValuationFormStartProps = FormProps & {
  onSubmit?: () => void;
  clearCookie?: boolean;
  placeholderText: string;
  submitText: string;
  status?: number;
};

export const ValuationFormStart = ({
  flexDirection,
  onSubmit,
  clearCookie = false,
  placeholderText,
  submitText,
  status,
}: ValuationFormStartProps) => {
  const { t } = useTranslation();
  const { setRegNr, regNr } = useValuationContext();
  const regNrRef = useClearRegNr(clearCookie);

  const router = useRouter();

  const valuate = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (regNrRef.current) {
        await setRegNr(
          regNrRef.current.value
            .trim()
            .replace(/[^A-Z0-9]/gi, '') // Replace anything that's not A-Z or 0-9
            .toUpperCase()
        );

        if (onSubmit) {
          onSubmit();
        } else {
          // navigate to valuation page
          router.push('/valuation');
        }
      }
    },
    [onSubmit, regNrRef, router, setRegNr]
  );

  const errorMessage = useMemo(() => {
    if (!status) {
      return null;
    }

    if (status === 404) {
      return t('Vehicle with this registration number was not found');
    }

    if (status === 400) {
      return t('Invalid registration number');
    }

    if (status === 429) {
      return t('Too many requests');
    }

    if (status === 403 || status === 401) {
      return t('Access denied');
    }

    if (status >= 500 && status <= 599) {
      return t('An error occurred while fetching the valuation');
    }

    return null;
  }, [status, t]);

  return (
    <Container>
      <Form onSubmit={valuate} flexDirection={flexDirection}>
        <Input
          ref={regNrRef}
          name="regNr"
          placeholder={placeholderText}
          $fullWidth
          defaultValue={regNr}
          type="text"
          aria-label={placeholderText}
          $uppercase
        />
        <Button type="submit" $fullWidth>
          {submitText}
        </Button>
      </Form>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};
