'use client';
import { useCallback, useState } from 'react';
import { ValuationContext } from './ValuationContext';
import { useReCaptcha } from 'next-recaptcha-v3';

type ValuationAction = {
  type: 'SET_PACKAGES' | 'SET_STANDARD_EQUIPMENT' | 'SET_EXTRA_EQUIPMENT';
  payload: string[];
};

const initialState = {
  packages: [] as string[],
  standardEquipment: [] as string[],
  extraEquipment: [] as string[],
};

const reducer = (state = initialState, action: ValuationAction) => {
  switch (action.type) {
    case 'SET_PACKAGES':
      return {
        ...state,
        packages: action.payload,
      };
    case 'SET_STANDARD_EQUIPMENT':
      return {
        ...state,
        standardEquipment: action.payload,
      };
    case 'SET_EXTRA_EQUIPMENT':
      return {
        ...state,
        extraEquipment: action.payload,
      };
    default:
      return state;
  }
};

type ValuationProviderProps = {
  children: React.ReactNode;
  initialRegNr: string;
  setInitialRegNr: (regNr: string, token: string) => Promise<void>;
  clearInitialRegNr: () => Promise<void>;
  initialDistance: number | null;
  initialPackages: string[];
  initialStandardEquipment: string[];
  initialExtraEquipment: string[];
  setStoredFilters: (
    filters: {
      distance?: number | null;
      packages?: string[];
      standardEquipment?: string[];
      extraEquipment?: string[];
    },
    token: string
  ) => Promise<void>;
};

export const ValuationProvider = ({
  children,
  initialRegNr,
  setInitialRegNr,
  clearInitialRegNr,
  initialDistance,
  initialPackages,
  initialStandardEquipment,
  initialExtraEquipment,
  setStoredFilters,
}: ValuationProviderProps) => {
  const { executeRecaptcha } = useReCaptcha();

  const [regNr, updateRegNr] = useState(initialRegNr);
  const [distance, setDistance] = useState<number | null>(initialDistance);

  const [state, setState] = useState({
    packages: initialPackages,
    standardEquipment: initialStandardEquipment,
    extraEquipment: initialExtraEquipment,
  });

  const setRegNr = useCallback(
    async (regNr: string) => {
      // Generate ReCaptcha token
      const token = await executeRecaptcha('set_reg_nr');

      updateRegNr(regNr);
      await setInitialRegNr(regNr, token);
    },
    [executeRecaptcha, setInitialRegNr]
  );

  const updateFilters = useCallback(
    (next: string[], prev: string[], action: ValuationAction['type']) => {
      const isSelected = next.some(pkg => prev.includes(pkg));

      if (isSelected) {
        const filtered = prev.filter(pkg => !next.includes(pkg));
        const nextState = reducer(state, { type: action, payload: filtered });
        setState(nextState);
        return;
      }

      const merged = Array.from(new Set([...prev, ...next]));

      const nextState = reducer(state, { type: action, payload: merged });
      setState(nextState);
    },
    [state]
  );

  const setPackages = useCallback(
    (nextPackages: string[]) => {
      updateFilters(nextPackages, state.packages, 'SET_PACKAGES');
    },
    [state.packages, updateFilters]
  );

  const setStandardEquipment = useCallback(
    (nextStandardEquipment: string[]) => {
      updateFilters(
        nextStandardEquipment,
        state.standardEquipment,
        'SET_STANDARD_EQUIPMENT'
      );
    },
    [state.standardEquipment, updateFilters]
  );

  const setExtraEquipment = useCallback(
    (nextExtraEquipment: string[]) => {
      updateFilters(
        nextExtraEquipment,
        state.extraEquipment,
        'SET_EXTRA_EQUIPMENT'
      );
    },
    [state.extraEquipment, updateFilters]
  );

  const storeFilters = useCallback(
    async ({ distance }: { distance: number | null }) => {
      const token = await executeRecaptcha('store_filters');
      setDistance(distance);

      await setStoredFilters({ ...state, distance }, token);
    },
    [executeRecaptcha, setDistance, setStoredFilters, state]
  );

  const clearRegNr = useCallback(async () => {
    updateRegNr('');
    setDistance(null);
    setState(initialState);
    await clearInitialRegNr();
  }, [clearInitialRegNr, setDistance, updateRegNr]);

  return (
    <ValuationContext.Provider
      value={{
        regNr,
        setRegNr,
        clearRegNr,
        distance,
        packages: state.packages,
        setPackages,
        standardEquipment: state.standardEquipment,
        setStandardEquipment,
        extraEquipment: state.extraEquipment,
        setExtraEquipment,
        storeFilters,
      }}
    >
      {children}
    </ValuationContext.Provider>
  );
};
